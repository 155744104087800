<template>
  <validation-observer v-slot="{ handleSubmit }" tag="div">
    <v-form @submit.prevent="handleSubmit(onSubmit)">
      <template #default>
        <v-form-row>
          <v-form-field label="ПОЧТА" rules="required|email">
            <template #default="{ validationErrors }">
              <v-input v-model="user.email" :is-error="!!validationErrors.length" type="text" />
            </template>
          </v-form-field>
        </v-form-row>
        <v-form-row>
          <v-form-field label="ПАРОЛЬ" rules="required">
            <template #default="{ validationErrors }">
              <router-link class="login-staff__link" :to="{ name: 'password-reset' }">Забыли пароль?</router-link>
              <v-input v-model="user.password" :is-error="!!validationErrors.length" type="password" />
            </template>
          </v-form-field>
        </v-form-row>
        <v-form-row v-if="backendErrorMessage" class="login-staff__error">
          <v-form-error>
            {{ backendErrorMessage }}
          </v-form-error>
        </v-form-row>
        <v-form-row>
          <v-form-checkbox v-model="isRememberedSession">Запомнить меня</v-form-checkbox>
        </v-form-row>
      </template>
      <template #footer>
        <v-button primary type="submit">Войти</v-button>
      </template>
    </v-form>
  </validation-observer>
</template>

<script>
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormError from '@/components/form/VFormError.vue'
import VInput from '@/components/common/VInput.vue'
import VButton from '@/components/common/VButton.vue'
import VFormCheckbox from '@/components/common/VCheckbox.vue'
import { authService } from '@/services/http'
import { ENTRY_ROUTE_NAME_FOR_REDIRECT } from '@/constants/routes'
import { loadingService } from '@/services/loading'

export default {
  name: 'LoginAuth',
  components: { VFormCheckbox, VFormField, VFormRow, VButton, VInput, VForm, VFormError },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
      isRememberedSession: true,
      backendErrorMessage: '',
      loading: false
    }
  },
  watch: {
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  methods: {
    onSubmit() {
      this.loading = true
      authService
        .authStaff(this.user)
        .then(tokens => {
          authService.setTokensSync(this.isRememberedSession)
          authService.setAuthTokens(tokens)
        })
        .then(() => {
          this.$router.push({ name: ENTRY_ROUTE_NAME_FOR_REDIRECT, params: { trackSignIn: true } })
        })
        .catch(error => {
          if (error.code === 101) {
            this.backendErrorMessage = 'Неверный email или пароль'
          } else if (error.code === 103) {
            this.backendErrorMessage = 'Необходимо продлить подписку. Свяжитесь с поддержкой.'
          } else {
            this.backendErrorMessage = 'Неизвестная ошибка'
            throw error
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
